import { Component } from "react";

class Error extends Component {
    render() {
        return (
            <div id="main">
                <div className="fof">
                    <h1>Error 404</h1>
                </div>
            </div>
        );
    }
}

export default Error;